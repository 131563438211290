<template>
  <x-dialog :proxy="couponsDialog">
    <en-table-layout :tableData="tableData.data">
      <template slot="toolbar">
        <el-form-item label="卡券应用类型">
          <el-select style="width: 100px" v-model="params.card_type" size="small" placeholder="请选择" clearable>
            <el-option v-for="item in mallTypeList" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input style="width: 280px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="卡券名称" value="card_name"></el-option>
              <el-option label="所属商城" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="search" size="small" type="primary">
            搜索
          </el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column prop="card_name" label="卡券名称" show-overflow-tooltip />
        <el-table-column prop="card_value" label="卡券面值/价位" width="120" />
        <el-table-column prop="shop_name" label="所属商城" show-overflow-tooltip />
        <el-table-column prop="card_type" label="卡券应用类型" width="120">
          <template slot-scope="scope">
            {{ scope.row.card_type | cardTypeFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="discount_rate" label="折扣率(标品)" width="120">
          <template slot-scope="scope">
            {{ scope.row.discount_rate + "%" }}
          </template>
        </el-table-column>
        <el-table-column prop="customized_discount_rate" show-overflow-tooltip label="模板折扣率" width="120">
          <template slot-scope="scope">
            {{ scope.row.customized_discount_rate + "%" }}
          </template>
        </el-table-column>
        <el-table-column prop="apply_discount_rate" show-overflow-tooltip label="定制折扣率" width="120">
          <template slot-scope="scope">
            {{ scope.row.apply_discount_rate + "%" }}
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="tableData.page_size" :total="tableData.data_total" />
    </en-table-layout>
  </x-dialog>
</template>
<script>
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { getShopExtAuth } from '@/api/order';
import { getShopLv } from '@/api/shop';
import { getCustomizedDiscountCardsList } from '@/api/settingCustomer';
export default {
  name: 'settingcoupon',
  components: {
    XDialog,
    EnTableLayout
  },
  filters: {
    cardTypeFilter (card_type) {
      switch (card_type) {
        case 3:
          return '现金储值卡'
        case 0:
          return '双选储值卡'
        case 5:
          return 'AI智能套餐卡'
        case 6:
          return '计次卡'
        default:
          return '宅配卡'
      }
    }
  },
  data () {
    return {
      //来源应用数组
      mallTypeList: [
        { name: '全部', value: '' },
        { name: '现金储值卡', value: 3 },
      ],
      parentId: '',//父元素传递的id
      key_word: 'card_name',
      params: {
        keyWord: '',
        card_type: "",
        page_size: 20,
        page_no: 1
      },
      tableData: {
        data: [],
        total: 0
      },
      couponsDialog: $xDialog.create({
        title: "申请详情",
        width: "65vw",
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: "cash-coupon-manager-main-dialog"
      }),
    }
  },
  async created () {
    this.getTableList()
    //判断大平台开关
    //判断大平台开关
    const doubledata = await getShopExtAuth();
    const aicard20 = await getShopLv();
    if (doubledata.double_choice_mall_open == "OPEN") this.mallTypeList.push({ name: '双选储值卡', value: 0 })
    if (aicard20.data.has_intelligent_card20 == 1) {
      this.mallTypeList.push({ name: 'AI智能套餐卡', value: 5 })
    }
    if (doubledata.counting_card_open == "OPEN") {
      this.mallTypeList.push({ name: '计次卡', value: 6 })
      this.mallTypeList.push({ name: '宅配卡', value: 7 })
    }
  },
  methods: {
    show (row) {
      this.key_word = 'card_name'
      this.params = {
        keyWord: '',
        card_type: "",
        page_size: 20,
        page_no: 1,
        // delete_status: 0
      },
        this.parentId = row.id;
      this.params.audit_id = this.parentId;
      this.couponsDialog.display();
      this.$nextTick(() => {
        this.getTableList()
      })
    },
    /**搜索 */
    search () {
      this.getTableList(1)
    },
    /**查询卡券列表 */
    getTableList (page_no) {
      let params = {
        ... this.params
      };
      page_no && (params.page_no = 1);
      params[this.key_word] = params.keyWord;
      delete params.keyWord;
      getCustomizedDiscountCardsList(params).then((res) => {
        this.tableData = res;
      });
    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.getTableList();
    },
    handleCurrentChange (val) {
      this.params.page_no = val;
      this.getTableList();
    }
  }
}
</script>
<style lang="scss" scoped></style>